import React from "react"
import { createGlobalStyle } from "styled-components"
import Theme from "./src/Themes/theme"

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'fontello';
    src: url('/fonts/fontello.eot?85878511');
    src: url('/fonts/fontello.eot?85878511#iefix') format('embedded-opentype'),
          url('/fonts/fontello.woff?85878511') format('woff'),
          url('/fonts/fontello.ttf?85878511') format('truetype'),
          url('/fonts/fontello.svg?85878511#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
  }
  html {
      font-size: 90%;

      @media only screen and (max-width: 900px) {
          font-size: 85%;
      }
  }

  body, html {
    background-color: white;
    overflow-x: hidden;
    font-family: sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(props) => props.theme.fonts.main};
    font-weight: 500;
  }
  h1 {
    font-size: 2.5rem;
    line-height: 1.5;
  }
  h2 {
    font-size: 3rem;
    line-height: 1.5;
  }
  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  h4 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  h5 {
    font-size: 2.2rem;
  }
  h6 {
    font-size: 1.8rem;
  }
  p, a, li {
    font-family: ${(props) => props.theme.fonts.main};
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  i.custom-icon {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
  }

  a, button {
    cursor: pointer;
  }

  .close-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    top: 20px;
    right: 10px;
    border: 2px solid black;
    border-radius: 150px;
    font-size: 1.2rem;
    color: black;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover, &:focus {
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.green} !important;
      background-color: ${(props) => props.theme.colors.green};
    }
  }
`

export const wrapRootElement = ({ element }) => (
  <Theme>
    <GlobalStyles />
    {element}
  </Theme>
)
