import React from "react"
import { ThemeProvider } from "styled-components"

const theme = {
  fonts: {
    main: "sans-serif",
    body: "monospace",
  },
  colors: {
    darkGreen: "#29596C",
    red: "#9E5663",
    blue: "#366DA1",
    green: "#5F8368",
    black: "#28373E",
    lightGrey: "#708790",
    white: "#F5F5F5",
    yellow: "#f6e29b;",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 50rem)",
    tablet: "only screen and (max-width: 65rem)",
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
